jQuery(function($) {
	$("body").addClass("shortform");
 	var myVariable = document.getElementById("postcode").value;
  
	if ( $('input[name="fuel_type"]').is(':checked') && myVariable !== 'Enter your postcode/suburb' && myVariable !== '' ) {
	 
    	var stepopen = '#step' + (parseInt($('.formstep.activeform').attr('id').split('step')[1]) + 2);
        var steptovalidate = '#' + $('.formstep.activeform').attr('id');
      	go_next(stepopen, steptovalidate, $(this));
    } else if ( $('input[name="fuel_type"]').is(':checked'))  {
    	var stepopen = '#step' + (parseInt($('.formstep.activeform').attr('id').split('step')[1]) + 1);
        var steptovalidate = '#' + $('.formstep.activeform').attr('id');
      	go_next(stepopen, steptovalidate, $(this));
    }





    $('.submit-btn,.page-template-tpl-new-formpage-v6 .step-option-item__radio').click(function(e) {
        var stepopen = '#step' + (parseInt($('.formstep.activeform').attr('id').split('step')[1]) + 1);
        if ($(this).attr('data-next'))
            stepopen = $(this).attr('data-next');
        var steptovalidate = '#' + $('.formstep.activeform').attr('id');
        if ($(this).hasClass('disable-submit'))
            return false;

        if(steptovalidate === '#step3')
            stepopen = $('input[name="connection_type"]:checked').val() === 'Business' ? '#step4': '#step5';

		$('[data-toggle="tooltip"]').tooltip('enable');
        go_next(stepopen, steptovalidate, $(this));
		 
    });
	$('[data-toggle="tooltip"]').tooltip();
    
	$("input[type='radio']").click(function(){
		$(this).closest(".step-options-container").find(".error-state").each(function(){
			$(this).removeClass("error-state");
		});
		var tooltip_id  = $(this).closest(".step-options-container").attr("aria-describedby");
		$("#"+tooltip_id).remove();
		
	});
	$('.submit_form').click(function() {
		$('[data-toggle="tooltip"]').tooltip('enable');
        stepopen = $(this).attr('data-value');
        steptovalidate = '#' + $(this).parents('.formstep').attr('id');
        if ($(this).hasClass('disable-submit')) {
            return false;
        }

        if (validateMyAjaxInputs(stepopen, steptovalidate)) {
            disableAutoComplete($("#findDeals"), true);
            setPartner($('input[name="connection_type"]:checked'));
        }
	});
    
    $('#findDeals').submit(function() {
        if (enablesubmit === false) {

            triggerSub($(this))

            return false;
        }
    });
    $('input').keydown(function(e) {
        var current_form = $(this).parents('form');
        var key = event.keyCode || event.charCode;
        if (key === 13) {
            current_form.find('.btn-wrap .submit-btn').click();

            triggerSub(current_form)
        }
    });

    $('.back-btn, .step-back').click(function(e) {
        
        e.preventDefault();
        // history.back(-1);
		
		//modify
        var stepopen = '#step' + (parseInt($('.formstep.activeform').attr('id').split('step')[1]) - 1);
        if ($(this).attr('data-next'))
            stepopen = $(this).attr('data-next');
        var steptovalidate = '#' + $('.formstep.activeform').attr('id');
        if ($(this).hasClass('disable-submit'))
            return false;

		if(steptovalidate == "#step8"){
			stepopen = $('#findDeals').hasClass('skip_rent_moving') ? "#step3" : "#step6";
		} else if(steptovalidate == "#step5"){
			stepopen = $('input[name="connection_type"]:checked').val() === 'Business' ? "#step4" : "#step3";
		} 

		// animate
		$(steptovalidate).addClass("animated faster fadeOutRight");
		setTimeout(function(){
			$(steptovalidate).removeClass("animated faster fadeOutRight activeform").addClass("d-none");
			
			$(stepopen).addClass("animated faster fadeInLeft activeform").removeClass("d-none");
			setTimeout(function(){
				$(stepopen).removeClass("animated faster fadeInLeft");
			}, 205);
		}, 205);

		
		
    });

    
    $('.back-btn-step3').click(function(e) {
        e.preventDefault();
        history.back(-5);
        
        //modify
        var stepopen = '#step' + (parseInt($('.formstep.activeform').attr('id').split('step')[1]) - 5);
        if ($(this).attr('data-next'))
            stepopen = $(this).attr('data-next');
        var steptovalidate = '#' + $('.formstep.activeform').attr('id');
        if ($(this).hasClass('disable-submit'))
            return false;

        if(steptovalidate == "#step8"){
            stepopen = "#step6";
        }

        // animate
        $(steptovalidate).addClass("animated faster fadeOutRight");
        setTimeout(function(){
            $(steptovalidate).removeClass("animated faster fadeOutRight activeform").addClass("d-none");
            
            $(stepopen).addClass("animated faster fadeInLeft activeform").removeClass("d-none");
            setTimeout(function(){
                $(stepopen).removeClass("animated faster fadeInLeft");
            }, 205);
        }, 205);

        
        
    });


    // STEP 2 GA CLICK EVENT
    $('#step2 .btn').click(function(e) {
        e.preventDefault();
       
        dataLayer.push({
            gtm: {
                elementId: 'step-2__A'
            },
            event: 'gtm.click'
        });
         
    });


    // $('[data-toggle="tooltip"]').tooltip('show');


    $(document).on('keypress', 'input', function (e) {
        if (e.which == 13) {
            e.preventDefault();
            var $next = $('[tabIndex=' + (+this.tabIndex + 1) + ']');
            if (!$next.length) {
                $next = $('[tabIndex=1]');
            }
            $next.focus();
        }
    });
});

function setPartner(connectionType, version) {
    var connectionType = connectionType.val();
    var isdev = '0';
    var postcode = document.getElementById("postcode_hidden").value;
    var location = $("#postcode").val().split(',');
    var state = $.trim(location[1]).toUpperCase();
    var host = window.location.host;
    var get_partner_url = apiUrl + 'lead/partner';
    // var get_partner_url = host.indexOf('.loc') > -1 || host.indexOf('dev.') > -1 || host.indexOf('devprelive.') > -1 || host.indexOf('staging.') > -1 ? 'https://staging.hearingaidapi.alternativemedia.com.au/get-partner' : 'https://hearingaidapi.alternativemedia.com.au/get-partner';

    if(host.indexOf('.loc') > -1 || host.indexOf('dev.') > -1 || host.indexOf('devprelive.') > -1 || host.indexOf('staging.') > -1) {
      isdev = '1';
    }

    apikey = 'asdfhlj2h345adfjkhl45CAG';
    
    $.ajax({
        type: "GET",
        data: {
          connection_type: connectionType,
         // postcode: postcode,
          state: state,
          apikey: apikey,
        },
        url: get_partner_url,
        success: function(resp) {
            $('#partner_api').val(resp.data.partner_rule);
            $('#partner_ty').val(resp.data.partner_thankyou_url)
            ajaxButtonClicked();
        },
        error: function(err) {
            var error = err.error;
            $('#partner_api').val('electricitywizard');
            $('#partner_ty').val('/thank-you-ew/?');
            ajaxButtonClicked();
        }
    });
}



function go_next(stepopen, steptovalidate, elem) {
		
    if (validateMyAjaxInputs(stepopen, steptovalidate)) {
        $('.tooltip').remove();


        /*if ( $('#step-3__A').is(':checked')) {

            $('#partner_api').val('electricitywizard');
            $('#partner_ty').val('/thank-you-ew/?');
             
        } else {
            $('#partner_api').val('mic');
            $('#partner_ty').val('/thank-you-mic/?');
             
        }*/
        
        if ($(steptovalidate).find('input[data-validate="address"]').length > 0) {
            $(steptovalidate).find('input[data-validate="address"]').each(function(i, value) {
                prefill_hidden_address($(this));
            });
        }
        
        if (stepopen === 'submitAjax' || stepopen === 'submit') {
            if (stepopen === 'submit') {
                enablesubmit = true;
                var submitForm = $(steptovalidate).parents('form').length > 0 ? $(steptovalidate).parents('form') : $(steptovalidate);
                submitForm.submit();
            } else {
                var sms_toggle = false;
                var phoneval = $('input[name=phone]').val();
                if ($('#property-business').is(':checked') || $('input[name=connectionType]:checked').val() === 'business')
                    sms_toggle = true;
                if (sms_toggle && phonevalidation.test(phoneval)) {
                    submitSMScode(phoneval);
                    $('#sms_resend_modal').modal({
                        show: true
                    });
                } else {
                    ajaxButtonClicked();
                }
            }
            elem.addClass('disable-submit').attr('disabled', true);
        } else {
            window.location.hash = stepopen;
        }

        //modify
        $(steptovalidate).addClass("animated faster fadeOutLeft").removeClass('activeform');
        setTimeout(function(){
            $(steptovalidate).removeClass("animated faster fadeOutLeft").addClass("d-none");
            
            $(stepopen).addClass("animated faster fadeInRight activeform").removeClass("d-none");
            setTimeout(function(){
                $(stepopen).removeClass("animated faster fadeInRight");
            }, 200);

            $("html, body").animate({
                scrollTop: 0
            }, 500, "swing");

        }, 200);
        
        var step7 = "#step7";
        if(stepopen == step7){
            setTimeout(function(){
                var go_step8 = "#step8";

                $(step7).addClass("animated faster fadeOutLeft").removeClass('activeform');
                setTimeout(function(){
                    $(step7).removeClass("animated faster fadeOutLeft").addClass("d-none");
                    
                    $(go_step8).addClass("animated faster fadeInRight activeform").removeClass("d-none");
                    setTimeout(function(){
                        $(go_step8).removeClass("animated faster fadeInRight");
                    }, 200);
                }, 200);
            }, 3000);
        }
        $(".error-state").removeClass('error-state');
    
    }else{
        $(steptovalidate).find("input[type='radio']").each(function(){
            $(this).siblings('label').addClass('error-state');
            $('[data-toggle="tooltip"]').tooltip({trigger:"manual"}).tooltip('show');
        });
        $(steptovalidate).find("input[type='text']").each(function(){
            $(steptovalidate+".step-form.input-group.error-state").addClass('error-state');
            $('[data-toggle="tooltip"]').tooltip({trigger:"manual"}).tooltip('show');
        });
    }

}


function triggerSub(currentform) {
    
    if(currentform.hasClass('micform')) {
        var current_step = $('.formstep.activeform');
        current_step.find('.submit-btn').click();
        current_step.find('.submit_form').click();
    }
}
