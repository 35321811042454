var apikey = '2zey7102ld5pfxre68ku';

// validator methods
var errorMsg,
	dynamicErrorMsg = function () { return errorMsg; }


var smsVerificationCode = '9876',
	phonevalidation = new RegExp('^0(4){1}[0-9]{8}$'),
	sms_verified = false;

jQuery.validator.addMethod("phone", function(value, element) {
	var result = true;

	if(!/^0(2|3|4|7|8){1}[0-9]{8}$/.test(value) || !validateFakeData(value))
		result =  false;

	return result;
}, "Please enter a valid 10 digit mobile number");

jQuery.validator.addMethod("address", function(value, element) {
	return this.optional(element) || /^(.+),[\s]*(.+),[\s]*(\d{4})$/.test(value);
}, "Please select your suburb from the drop down list after typing in your postcode.");

jQuery.validator.addMethod("checkbox", function(value, element) {
	var result = true;
	if(!$(element).is(':checked')) {
      result = false;
    }
    return result;
}, "Please accept terms and conditions");


jQuery.validator.addMethod("emailExtra", function(value, element) {
	var result = true,
		emailVal = value;

	if (emailVal.indexOf('..') > -1 || emailVal.indexOf('.') === 0 || emailVal.indexOf('.@') > -1 || emailVal.indexOf('.') === -1 || emailVal.split('@')[1].indexOf('.') === -1 || !validateFakeData(value)) {
		result = false;
	}

	return result;
}, "Please enter a valid email.");


$.validator.addMethod("address_extra", function(value, element) {
	var result = true;

	if(!/^(.+),[\s]*(.+),[\s]*(\d{4})$/.test(value) || !postCode_validation($(element))) {
		result = false;
	}

	return result;

}, "Please select your suburb from the drop down list after typing in your postcode.");

var errorMsg_postcode,
	dynamicErrorMsg_postcode = function () { return errorMsg_postcode; }

$.validator.addMethod("postcodeonly", function(value, element) {
	var result = true,
			postcode_result = postCodeOnlyvalidation($(element));
			errorMsg_postcode = "Please enter valid postcode";

	if($(element).attr('data-placeholder') == value || !/^(\d{4})$/.test(value) || !postcode_result) {
		result = false;
	}

	if(typeof postcode_result == 'object' && typeof postcode_result.state_error !== 'undefined') {
		
		errorMsg_postcode = 'We are unable to offer you a comparison service in the location you have entered due to state/territory energy supplier restrictions.';
		result = false;
	}

	return result;

}, dynamicErrorMsg_postcode);

$.validator.addMethod("businessname", function(value, element) {
	errorMsg = "Please enter your Business name";
	var result = true,
		elemval = $.trim(value),
		placeholder = $(element).attr('data-placeholder');

	if(!elemval.length) {
		errorMsg = "Please enter your Business name";
		result = false;
	} else if(elemval == placeholder) {
		errorMsg = 'This field is required';
		result = false;
	}

	return result;

}, dynamicErrorMsg);

jQuery.validator.addMethod("fullname", function(value, element) {
	errorMsg = 'Please enter full name';
	var result = true,
		elemval = $.trim(value),
		fullname_test = elemval.split(' '),
		patt = new RegExp(/^[a-zA-Z-' ]+$/),
		firstname = '',
		lastname = '',
		placeholder = $(element).attr('data-placeholder');

	if(fullname_test.length < 2) {
	  errorMsg = 'Please enter your first name and last name';
	  result = false;
	}
	else if(elemval == placeholder) {
		errorMsg = 'This field is required';
		result = false;
	}
	else {
	  lastname = fullname_test[fullname_test.length - 1];
	  for (var i = 0; i < fullname_test.length - 1; i++) {
		 if (i > 0) {
			firstname += ' ';
		 }
		 firstname += fullname_test[i];
	  }
	  if(firstname.length < 2 || lastname.length < 2) {
		errorMsg = 'Name under 2 characters'
		result = false;
	  } else if(firstname == lastname){
		errorMsg = 'Firstname and lastname identical';
		result = false;
	  } else if(!patt.test(elemval)) {
		errorMsg = 'Please enter only alphabets';
		result = false
	  }
	}

	return result;

}, dynamicErrorMsg);

// FIRSTNAME VALIDATOR

$.validator.addMethod("firstname", function(value, element) {

    errorMsg = 'Please enter first name';
    var result = true,
    elemval = $.trim(value),
    patt = new RegExp(/^[a-zA-Z-' ]+$/);
    if(elemval.length < 2 ) {
		errorMsg = 'Name under 2 characters'
		result = false;
	} else {
		if (!patt.test(elemval)) {
			errorMsg = 'Please enter only alphabets';
			result = false
		}
	}

	return result;

}, dynamicErrorMsg);

// LASTNAME VALIDATOR

$.validator.addMethod("lastname", function(value, element) {
    errorMsg = 'Please enter last name';
    var result = true,
    elemval = $.trim(value),
    patt = new RegExp(/^[a-zA-Z-' ]+$/);
    if(elemval.length < 2 ) {
		errorMsg = 'Last name under 2 characters'
		result = false;
	} else {
		if (!patt.test(elemval)) {
			errorMsg = 'Please enter only alphabets';
			result = false
		}
	}

	return result;

}, dynamicErrorMsg);

var siteURL = window.location.href;
// API url
var apiUrl = '';
	if (siteURL.indexOf('dev.') >= 0 || siteURL.indexOf('.loc') >= 0) // Dev env
	{
		apiUrl = 'https://dev.api.alternativemedia.com.au/';
	} else if (siteURL.indexOf('staging.') !== -1) {
		apiUrl = '//staging.api.alternativemedia.com.au/';
	}
	// else if(siteURL.indexOf('.loc') >= 0) /// Local env
	// {
	//   apiUrl = '//api.local/';
	// }
	else // Production env
	{
		apiUrl = '//api.alternativemedia.com.au/';
	}

jQuery(function($) {

	// randomized name
	disableAutoComplete();

	var sms_enabled = $('#sms_default').length > 0 ? true : false;//true;

	$('input[type="tel"]').keydown(function(e) {
		if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 224, 17, 91, 93]) !== -1 ||
			// Allow: Ctrl+A
			(e.keyCode == 65 && e.ctrlKey === true) ||
			(e.keyCode == 65 && e.metaKey === true) ||
			(e.keyCode >= 35 && e.keyCode <= 39)) { // Allow: home, end, left, right
			// let it happen, don't do anything
			return;
		}
		// Ensure that it is a number and stop the keypress
		if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
			e.preventDefault();
		}
	});

	$('.phone').keypress(function(e) {
		var txtVal = $(this).val();
		if (txtVal.length > 9 && e.keyCode != 8) {
			return false;
		} else {
			return true;
		}
	});

	$('.option-set a').click(function(e) {
		e.preventDefault();
		var inputval = $(this).attr('data-value');
		$(this).siblings('input').val(inputval);
		$(this).siblings('a').removeClass('active');
		$(this).addClass('active');
	});

	$('#findDeals').submit(function() {
		if (enablesubmit === false) {
			return false;
		}
	});

	$('.submit-step, .form-submit').click(function() {
		stepopen = $(this).attr('data-value');

		steptovalidate = '#' + $(this).parents('.formstep').attr('id');

		if ($(this).hasClass('disable-submit')) {
			console.log('disabled')
			return false;
		}
		if (validateMyAjaxInputs(stepopen, steptovalidate)) {

			if (stepopen === 'submitAjax') {

				disableAutoComplete($(steptovalidate), true);

				$(this).addClass('disable-submit').attr('disabled', true);
				if (sms_enabled) {

					var sms_toggle = false;
					var phoneval = $('input[name=phone]').val();
					var sms_cookie = checkCookie('sms_enabled') ? true : false; // cookie set from tagmanager

					if (($('#property-business').is(':checked') || $('#connection_type').val() === 'Business') || ((!$('#property-business').is(':checked') || $('#connection_type').val() != 'Business') && sms_cookie == true && $('#partner_api').val() == 'mic'))
						sms_toggle = true;

					if(sms_cookie == true && (siteURL.indexOf('/form/step2-tw') !== -1 || siteURL.indexOf('/form/step2-yc') !== -1))
						sms_toggle = true;

					if (sms_toggle && phonevalidation.test(phoneval)) {

						submitSMScode(phoneval);
						$('#sms_resend_modal').modal({
							show: true
						});

					} else {
						ajaxButtonClicked();
					}

				} else {
					$(this).addClass('disable-submit').attr('disabled', true);
					ajaxButtonClicked();
				}

			} else if (stepopen === 'submit') {

				disableAutoComplete($(steptovalidate), true);
				setCookie('formpage_access', window.location.pathname, 0.0347222222);
				localStorage.formpage_access = window.location.pathname;
				enablesubmit = true;
				if($(this).hasClass('comment-form-submit')) {
					$(steptovalidate).find('input[type=submit]').click();
				} else {
					$('#findDeals').submit();
				}

			} else {
				window.location.hash = stepopen;
				var summaryfield = '#' + $(this).parents('.formstep').find('input').attr('id');
				$(summaryfield + '-value').text($(summaryfield).val());
			}
		}

	});


	var width = $(window).width();
	$(window).resize(function(){
		if ($(window).width() != width) {
			$('.tooltip').remove();
			width = $(window).width();
		}
	});

	$('.radio-btn').click(function(e) {
		$(this).parents('.flex-field-wrap').find('.radio-error').remove();
	});

	// *************************** //
	// **** sms verification ***** //
	// *************************** //

	// verification input
	function onelength(elem) {
		var input_index = parseInt(elem.attr('id').split('inputsms')[1]) + 1,
			input_next = $('#inputsms' + input_index),
			input_val = elem.val();

		if (input_val.length > 1)
			elem.val(input_val.slice(0, 1))

		if (input_val.length >= 1 && input_index <= 4)
			input_next.focus();

	}

	$('.oneleng').keydown(function(event) {
		onelength($(this));

		var input_index = parseInt($(this).attr('id').split('inputsms')[1]) - 1,
			key = event.keyCode || event.charCode;

	    if( (key == 8 || key == 46) && input_index !== 0) {
	    	$(this).val('');
	        $('#inputsms' + input_index).focus();
	    }
	});

	$('.oneleng').keyup(function(){
		onelength($(this))
	});

	$('.oneleng').focus(function() {
		$(this).val('')
	});

	var resend_submit_error_msg = 'Please enter a valid number',
		resend_submit_success = 'SMS verification sent';

	// resend sms
	$('.resend_sms').click(function(e) {
		e.preventDefault();

		if ($('#phone_resend').val() !== '' && phonevalidation.test($('#phone_resend').val())) {

			$('#phone_resend').removeClass('error');
			$('.resend-submit-error span').text(resend_submit_success);
			$('.resend-submit-error').hide().addClass('sms-success').slideDown();

			// resend the sms
			userMobile = $('#phone_resend').val();
			$('input[name=phone]').val(userMobile);

			$('#phone_resend').val('');

			submitSMScode(userMobile);

		} else {

			$('#phone_resend').addClass('error');
			$('#phone_resend').focus();
			$('.resend-submit-error span').text(resend_submit_error_msg);
			$('.resend-submit-error').hide().removeClass('sms-success').slideDown();

		}

	});

	// verify sms
	$('.submit_verification').click(function(e){
		e.preventDefault();

		if (checkSmsCode($('#inputsms1').val() + $('#inputsms2').val() + $('#inputsms3').val() + $('#inputsms4').val())) {

			$(this).addClass('disabled').attr('disabled', true);

			$('.verification-submit-error').hide();

			$('#sms_resend_modal').modal('hide');

			sms_verified = true; // set to true and include on lead submission

			// submit lead
			ajaxButtonClicked();

		} else {

			$('#inputsms1, #inputsms2, #inputsms3, #inputsms4').addClass('error');

			$(this).removeClass('disabled').removeAttr('disabled');

			$('.verification-submit-error').hide().slideDown();

		}

	})

	$('#sms_resend_modal').on('hidden.bs.modal', function (e) {
		$('.submit-step, .form-submit, .go-submit, .submit_verification, .submit-btn').removeClass('disable-submit').removeAttr('disabled');
	});

	$('.step-back').click(function(e) {
		e.preventDefault();

		setTimeout(function() {
			hideFooterOnStep2To5();

			$(window).on('change', function() {
				hideFooterOnStep2To5();
			});
		}, 600);

		history.back(-1);
	});

	// thumbform nav
	$('.go-next').click(function(e) {
		e.preventDefault();
		var currentstep = location.hash !== '#' || location.hash !== '' ? location.hash : '#step2';
		var nextstep = '#step' + (parseInt(currentstep.split('#step')[1]) + 1);

		if (validateMyAjaxInputs(nextstep, currentstep)) {
			window.location.hash = nextstep
		}

		hideFooterOnStep2To5();

		$(window).on('change', function() {
			hideFooterOnStep2To5();
		});
	});

	$('.go-submit').click(function(e) {

		e.preventDefault();
		var currentstep = location.hash !== '#' || location.hash !== '' ? location.hash : '#step2';
		var nextstep = '#step' + (parseInt(currentstep.split('#step')[1]) + 1);

		if ($(this).hasClass('disable-submit')) {
			console.log('disabled')
			return false;
		}

		if (validateMyAjaxInputs(nextstep, currentstep)) {

			if (sms_enabled) {

				var sms_toggle = false;
				var phoneval = $('input[name=phone]').val();
				var sms_cookie = checkCookie('sms_enabled') ? true : false; // cookie set from tagmanager

				if ($('#property-business').is(':checked') || (!$('#property-business').is(':checked') && sms_cookie == true && $('#partner_api').val() == 'mic'))
					sms_toggle = true;

				if ($('#connection_type').length > 0)
					if ($('#connection_type').val() === 'Business')
						sms_toggle = true;

				if (sms_toggle && phonevalidation.test(phoneval)) {

					submitSMScode(phoneval);
					$('#sms_resend_modal').modal({
						show: true
					});

				} else {
					ajaxButtonClicked();
				}

			} else {
				ajaxButtonClicked();
			}
		}
	});


	// logo slider
	if($('.lender-logos').length > 0) {
		$('.lender-logo-slider').slick({
			slidesToShow: 8,
			slidesToScroll: 1,
			infinite: true,
			dots: false,
			arrows: false,
			autoplay: true,
 			autoplaySpeed: 2000,
			responsive: [
				{
					breakpoint: 991,
					settings: {
						slidesToShow: 6
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 4
					}
				},
				{
					breakpoint: 400,
					settings: {
						slidesToShow: 3
					}
				},
			]
		});
	}


	// multiform hashchange
	if($('#multiform').length > 0 && $('#multiform').val() === 'yes') {
		$(window).hashchange(function() {
			hideSteps();
			hashCheck();
		});
	}

	if (window.location.hash) {
		window.location.replace(redirecturl);
	}
	$('.formstep').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function() {
		$(this).removeClass('animated fadeIn');
	});
	
	if($('[data-toggle="tooltip"]').length > 0) $('[data-toggle="tooltip"]').tooltip();
});

// file upload
var file_elem = $('#bill_upload'),
	bill_data = [];

$(function(){
	file_elem.change(function(e) {

		var files = file_elem[0].files,
			reader_count = 0,
			filenames = [];

		// reset data for submission
		bill_data = [];

		for(var x = 0; x < files.length; x++) {

			var reader = new FileReader();
			filenames[x] = files[x].name;
			reader.readAsDataURL(files[x]);

			reader.onloadend = function(e) {
				console.log(e);
				var string = e.target.result,
					string_split = string.split(',');

				bill_data.push({ name : filenames[reader_count], base64data : string_split[1] });
				reader_count++;
			}
		}
	 $('.file__upload-name-text').html('<img src="https://asset.alternativemedia.com.au/wp-content/uploads/sites/2/2019/05/24145420/pdf-icon.png" />' + filenames);
     $('.upload__btn-wrapper').addClass('d-block');	
     $("#submit__upload-btn").removeAttr('disabled','disabled');
     $(".step-10").attr('disabled','disabled');
     $("#finish__text").removeClass('d-block');
     $('.file__upload-name').removeClass('d-none');

	});
});



// hide invalid postcode validation on address field keypress
$('input[data-format="address"]').each(function(){
	$(this).on('keypress',function(){
		$('.invalidpostcode-error').remove();
	});
});


var firstname = "",
	lastname = "";

var validateMyAjaxInputs = function (step, stepid) {
	var rules = {},
		messages = {},
		tooltip_options = {},
		result = false;

	$(stepid).find('input:not([type="hidden"]):not([type="radio"]):not([type="submit"]), select').each(function(i, value) {
		var validationformat = $(this).attr('data-format') !== undefined ? $(this).attr('data-format') : 'require',
			validationmessage = $(this).attr('data-msg') !== undefined ? $(this).attr('data-msg') : '',
			field_name = $(this).attr('name');

		rules[field_name] = {
			required: true
		}

		if (validationformat !== undefined && validationformat !== 'require')
			rules[field_name][validationformat] = true;

		if (validationmessage !== '')
			messages[field_name] = validationmessage;

		if($('body').hasClass('new-formpage')) {
			tooltip_options[field_name] = {
				placement: 'top'
			}
		}else{
			tooltip_options[field_name] = {
				placement: 'bottom'
			}
		}

	});

	var validator = $(stepid).parents('form').length > 0 ? $(stepid).parents('form') : $(stepid);

	validator.validate({
			rules: rules,
			messages: messages,
			tooltip_options: tooltip_options,
			invalidHandler: function(form, validator) {
				var offsetUse = validator.errorList[0].message.indexOf('service in the location') !== -1 && validator.errorList[0].method == 'postcodeonly' ? 130 : 50;
				var width = $(window).width();

				if (width < 767) {
					$('html, body').animate({
						scrollTop: $(validator.errorList[0].element).offset().top - offsetUse
					}, 100);
				}
				

			}
	});
		

	if ($(stepid).find('input[data-format="fullname"]').length > 0 && $(stepid).find('input[data-format="fullname"]').val() !== '') {
		$(stepid).find('input[data-format="fullname"]').each(function(i, value) {
			$(this).rules("add", {
				fullname: true
			});
		});
	}

	// FIRSTNAME VALIDATION

	if ($(stepid).find('input[data-format="firstname"]').length > 0 && $(stepid).find('input[data-format="firstname"]').val() !== '') {
		$(stepid).find('input[data-format="firstname"]').each(function(i, value) {
			$(this).rules("add", {
				firstname: true
			});
		});
	}

	// LASTNAME VALIDATION

	if ($(stepid).find('input[data-format="lastname"]').length > 0 && $(stepid).find('input[data-format="lastname"]').val() !== '') {
		$(stepid).find('input[data-format="lastname"]').each(function(i, value) {
			$(this).rules("add", {
				lastname: true
			});
		});
	}

	if ($(stepid).find('input[data-format="email"]').length > 0 && $(stepid).find('input[data-format="email"]').val() !== '') {
		$(stepid).find('input[data-format="email"]').each(function(i, value) {
			$(this).rules("add", {
				emailExtra: true
			});
		});
	}

	if ($(stepid).find('input[data-format="address"]').length > 0) {
		$(stepid).find('input[data-format="address"]').each(function(i, value) {

			$(this).rules('add', {
				address_extra: true
			});

		});
	}

	if ($(stepid).find('input[data-format="postcodeonly"]').length > 0) {
		$(stepid).find('input[data-format="postcodeonly"]').each(function(i, value) {

			$(this).rules('add', {
				postcodeonly: true
			});

		});
	}

	if ($(stepid).find('input[data-format="businessname"]').length > 0) {
		$(stepid).find('input[data-format="businessname"]').each(function(i, value) {

			$(this).rules('add', {
				businessname: true
			});

		});
	}

	if (validator.valid())
		result = true
	else
		result = false


	// extra postcode validation (valid/ zips, restricted states, valid zips)
	if ($(stepid).find('input[data-format="address"]').length > 0 && result === true) {
		$(stepid).find('input[data-format="address"]').each(function(i, value) {
			var elem = $(this);
			var postcodeValue = elem.val();
			var a = elem.offset();
			a.left += 0, a.top -= elem.outerHeight() + 10;
			if (postcodeValue !== '') {
				if (postCodevalidation('#' + elem.attr('id')) === false) {
					$(elem).focus();
					result = false;
					return false;
				}
			}

			if (invalidStates !== '') {
				for (var x = 0; x < invalidStates.length; x++) {
					// console.log(postcodeValue, invalidStates, postcodeValue.indexOf(invalidStates[x]) !== -1);
					if (postcodeValue.indexOf(invalidStates[x]) !== -1) {
						x = invalidStates.length;
						var tp = $('<div class="tooltip top in validity-tooltip invalidpostcode-error" style="opacity: 1"><div class="tooltip-arrow"></div><div class="tooltip-inner " style="max-width:280px">We are unable to offer you a comparison service in the location you have entered due to state/territory energy supplier restrictions.</div></div>').mouseover(function() {
							$(this).fadeOut()
						}).css(a).hide().appendTo("body").fadeIn();
						$(elem).focus();
						result = false;
					}
				}
			}

			if (validState !== '' && stateValidPostcode !== '' && result === true) {
				if (postcodeValue.indexOf(validState) !== -1) {
					var postExist = false;
					$.each(stateValidPostcode, function(i, val) {
						if (postcodeValue.indexOf(val) !== -1) {
							$(elem).focus();
							postExist = true;
							return false
						}
					});
					if (postExist === false) {
						var tp = $('<div class="tooltip top in validity-tooltip invalidpostcode-error" style="opacity: 1"><div class="tooltip-arrow"></div><div class="tooltip-inner " style="max-width:280px">We are unable to offer you a comparison service in the location you have entered due to state/territory energy supplier restrictions.</div></div>').mouseover(function() {
							$(this).fadeOut()
						}).css(a).hide().appendTo("body").fadeIn();
						$(elem).focus();
						result = false;
					}
				}
			}

		});
	}

	// radio validation
	if ($(stepid).find('input[data-format="radio"]').length > 0) {
		$('.radio-error').remove();
		$(stepid).find('input[data-format="radio"]').each(function(i, value) {
			var elem = $(this);
			if($('body').hasClass('new-formpage')) {
				var validationmessage = '<div class="tooltip fade top in radio-error" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner">This field is required.</div></div>';
			}else{
				var validationmessage = '<small class="radio-error">This field is required.</small>';
			}
			var getname = elem.attr('name');

			$('input[name="' + getname + '"]').each(function(i, value){
				$(this).parents('.flex-field-wrap').find('.radio-error').remove();
				if ($('input[name="' + getname + '"]:checked').length < 1 && $('input[name="' + getname + '"]').length === i + 1) {
					elem.parents('.flex-field-wrap').find('.flex-option-label').append(validationmessage);
					result = false;
				}
			});

		});
	}

	if($('body').hasClass('shortform') || $('body').hasClass('multiform-qs')) {
		// radio validation
		var count = 1;
		if ($(stepid).find('input[type="radio"]').length > 0) {
			$('.radio-error').remove();
			var validationmessage = '<div class="tooltip fade top in radio-error" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner">This field is required.</div></div>';


			$(stepid).find('input[type="radio"]').each(function(i, value) {
				var elem = $(this);
				var getname = elem.attr('name');
				if ($('input[name="' + getname + '"]:checked').length < 1) {
					result = false;
					count++;
				}
			});

		}

		if(!result)
			$(stepid).find('.radio-wrap').append(validationmessage);
	}


	return result;

}


// json import validation
var fakeEmail = [],
    fakeNumber = [];

// fake data validation
$(function(){
	// fake data list
    $.getJSON("https://asset.alternativemedia.com.au/fake.json", function (data) {
      fakeNumber = data.fakeNumber;
      fakeEmail = data.fakeEmail
    });
});

var validateFakeData = function (value) {
    var result = true;
    for(var i=0;i<fakeEmail.length;i++) {
		if(fakeEmail[i] === value) {
	        result = false;
	        break;
		}
    }
    for(var i=0;i<fakeNumber.length;i++) {
		if(fakeNumber[i] === value) {
	        result = false;
	        break;
		}
    }
	if( (value == '0400000000' || value == '0499999999') && (location.href.indexOf('dev.') !== -1 || location.href.indexOf('staging.') !== -1 || location.href.indexOf('.loc') !== -1) )
		result = true;
    return result
}

var stepopen, steptovalidate, stepaction, enablesubmit = false,
	laststep = 0;

// **** multiform specific scripts ***** //
laststep = '#step' + $('.formstep').length;
var step_count = $('.formstep').length;

var hideSteps = function () {
	$('.tooltip').remove();
	$('.formstep').removeClass('activeform animated fadeIn');
	$('.autocomplete-suggestions').hide()
}

var hashCheck = function () {
	$('.hidden-on-step, .errorMsg').hide();
	$('.show-on-step').show();
	$('.formstep').removeClass('activeform');
	$('.optin-form').addClass('opensteps');
	$('body').removeClass('showing-inner-steps');
	var stepInt = parseInt(location.hash.replace('#step', ''));

	progessBar(location.hash);

	if (location.hash !== '#') {

		if ($(location.hash).length > 0) {

			$(location.hash).addClass('activeform animated fadeIn');

			if (location.hash === '#step1') {
				$('.show-on-step').hide();
				$('.hidden-on-step').show();
			}

			if(location.hash !== '#step1' && stepInt < step_count) {
				$('body').addClass('showing-inner-steps');
			}
			if ((location.hash === '#step1' || stepInt === step_count) && $('body').hasClass('shortform')) {
				$('.show-on-step').hide();
			}

			if (location.hash === laststep) {
				$('.hide-on-last').hide();
				$('.show-on-last').show();
			} else {
				$('.hide-on-last').show();
				$('.show-on-last').hide();
			}

		} else {
			$('#step1, #start').addClass('activeform animated fadeIn');
			$('.optin-form').removeClass('opensteps');
			$('.show-on-step').hide();
			$('.hidden-on-step').show();
		}
	}

	setTimeout(function() {
		$('html, body').animate({
			'scrollTop': 0
		}, 300, 'swing');
	}, 50);
}

var progessBar = function (progress) {
	var progressb = parseInt(progress.replace('#step', '')),
		step_percent = 100 / step_count,
		step_width = ((progressb * step_percent) - step_percent) + '%',
		step_text = ((progressb * step_percent) - step_percent);
		step_text = Math.round(step_text);

	$('.progress-bar').css({
		'width' : step_width
	});

	$('.amount').text(step_text);

	if ($('#bar').length > 0) {

		$('.progress-b a').removeClass('active');

		for (var x = 0; x < progressb; x++) {
			$('.progress-b a:eq(' + x + ')').addClass('active');
		}

		var step_percent = 100 / $('.formstep').length - 1;

		$('.progress-bar').css({
			'width': (step_percent * parseInt(progressb)) + '%'
		})

	}
}

var hideFooterOnStep2To5 = function () {
	if ($(window).width() <= 991 && location.hash !== '#start' && location.hash !== '#step7' && location.hash !== '' && location.hash !== '#') {
		$('#footer').hide();
	} else {
		$('#footer').show();
	}
}

// autocomplete postcode
var choices = [];
var arr = [];
var suburbFull;
var suburValue;
var postValue;
var submitReport;
$(function() {
	$.getJSON("https://asset.alternativemedia.com.au/geodata.json", function(data) {
		$.each(data, function(name, value) {
			value[2] = value[2].length < 4 ? '0' + value[2] : value[2];
			arr.push("" + value.join(', '));
		});
		$('input[data-format="address"]').autoComplete({
			minChars: 1,
			source: function(term, suggest) {
				term = term.toLowerCase();
				var choices = arr;

				var matches = [];
				for (var i = 0; i < choices.length; i++) {
					if (~choices[i].toLowerCase().indexOf(term))
						matches.push(choices[i]);
					// if (matches.length > 4) {
					// 	i = choices.length;
					// }
				}
				suggest(matches);
			},
			onSelect: function(e, term, item) {
				$(this).focus();
				setTimeout(() => {
					prefill_hidden_address($('input[data-format="address"]'));
				}, 500);
				$('.autocomplete-suggestions').hide();
			},
			cache: false
		});
	});
});

var postCodevalidation = function (element) {
	var suburbExist = false;
	var postCodeVal = $(element).val().trim();
	for (var i = 0; i < arr.length; i++) {
		if (arr[i] === postCodeVal) {
			suburbExist = true;
			break;
		}
	}
	if (suburbExist === false && postCodeVal !== '') {
		var a = $(element).offset();
		a.left += 0, a.top += $(element).outerHeight() - 15;
		var tp = $('<div class="tooltip bottom in validity-tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner ">Please select your suburb from the drop down list after typing in your postcode.</div></div>').mouseover(function() {
			$(element).focus(), $(this).fadeOut()
		}).css(a).hide().appendTo("body").fadeIn();
		$(element).focus();
	}
	return suburbExist;
}


var postCode_validation = function (element) {
	var suburbExist = false;
	var postCodeVal = $(element).val().trim();
	for (var i = 0; i < arr.length; i++) {
		if (arr[i] === postCodeVal) {
			suburbExist = true;
			break;
		}
	}
	return suburbExist;
}


// prefill hidden
var prefill_hidden_address = function(elem){
	var address_val = elem.val().split(',');
	elem.siblings('.hidden_suburb').val(address_val[0].trim().toLowerCase());
	elem.siblings('.hidden_state').val(address_val[1].trim().toLowerCase());
	elem.siblings('.hidden_postcode').val(address_val[2].trim());
};

// postcode only related scripts
function limitKeypress(event, value, maxLength) {
	if (value != undefined && value.toString().length >= maxLength) {
			event.preventDefault();
	}
}

function postCodeOnlyvalidation(element) {
	var suburbExist = false,
			postCodeVal = element.val().trim(),
			suburbExist = false,
			postcodeSelected = ['Happy Valley, NT, 4825','BMungindi, QLD, 2406','Mayfield, ACT, 2540','Burra, ACT, 2620','Mildura, NSW, 3500','Castle Donnington, NSW, 3585','Bulga, NSW, 3586','Biggara, NSW, 3707','Cannon Creek, NSW, 4380'],
			generated_val = '';

		if(!/^(\d{4})$/.test(postCodeVal))
			return false;

		for (var i in arr) {
			if(checkFromArr(element, arr[i], postCodeVal)) {
				suburbExist = true;
				generated_val = arr[i].toLowerCase();
				break;
			}
		}

		for (var a in postcodeSelected) {
			if(checkFromArr(element, postcodeSelected[a], postCodeVal)) {
				suburbExist = true;
				generated_val = postcodeSelected[a].toLowerCase();
				break;
			}
		}
		if(suburbExist) {

			var curr_state = $.trim(generated_val.split(',')[1]);

			// check invalid state
			
			for(var s in invalidStates) {

				if(curr_state == invalidStates[s].toLowerCase()) {
					suburbExist = { 'state_error' : true };
					break;
				}

			}
			
		}

		return suburbExist;
	
}

function checkFromArr(elem, arrval, elemval) {
	var result = false;
	if(arrval.indexOf(elemval) !== -1) {
		$('#postcode').val(arrval);
		result = true;
	}
	return result;
}

$(function(){
	var postcodeOnlyField = $('.postcode-num');

	postcodeOnlyField.on('keypress', function(event){
			limitKeypress(event,$(this).val(),4)
	});

	postcodeOnlyField.keyup(function(){
		if($(this).val().length == 4)
			postCodeOnlyvalidation($(this))
	});

});

//remove url parameter
function removeUrlParam(key, sourceURL) {
    var rtn = sourceURL.split("?")[0],
        param,
        params_arr = [],
        queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";
    if (queryString !== "") {
        params_arr = queryString.split("&");
        for (var i = params_arr.length - 1; i >= 0; i -= 1) {
            param = params_arr[i].split("=")[0];
            if (param === key) {
                params_arr.splice(i, 1);
            }
        }
        rtn = rtn + "?" + params_arr.join("&");
    }
    return rtn;
}


// Get the utms from the cookie
var utms = checkCookie('utms') ? getCookie('utms') : ( localStorage.utms !== undefined ? localStorage.utms : '' );

// replaced utm_name
var api_utms = (utms.indexOf('utm_name') > -1 ? utms.replace('utm_name', 'utm_campaign') : utms);
	api_utms = api_utms.indexOf('+') !== -1 ? api_utms.replace(/\+/g, " ") : api_utms;

// set form action to include utm
var utmParam = (utms !== '' ? decodeURIComponent($.param(JSON.parse(utms))) : utms);
var formAction = $('#findDeals').attr('action');
var step1Href = $('#mapStep1').attr('action');
var queryString = window.location.search;

$.each(JSON.parse(utms),function(key){
	queryString = removeUrlParam(key,queryString);
});

if(utmParam.length>0){
	queryString = queryString+"&";
}
queryString = queryString.replace("?","");

$('#findDeals').attr('action', formAction + '?' +queryString+ utmParam);
$('#mapStep1').attr('action', step1Href + '?' +utmParam);

// submission

var ajaxButtonClicked = function () {
	$('.submit-step, .go-submit, .form-submit, .submit_verification, .submit-btn').addClass('disable-submit').attr('disabled', true);

	// Get the landing page
	var landingPage = checkCookie('landing_page') ? getCookie('landing_page') : ( localStorage.landing_page !== undefined ? localStorage.landing_page : window.location.href );

	// modify landing page cookie if it was saved as string 
	if(landingPage.indexOf('{') !== -1) {
		landingPage = JSON.parse(landingPage).landing_page;
		setCookie('landing_page', landingPage, 7);
		localStorage.removeItem('landing_page');
	}

	// First check whether the inputs are valid:
	$('#loader').addClass('show');
	$('.tooltip').remove();

	var location = $("#postcode").val().split(',');
	$('#postcode_hidden').val($.trim(location[2]));
	$('#state_hidden').val($.trim(location[1]).toLowerCase());
	$('#suburb_hidden').val($.trim(location[0]).toLowerCase());

	if ($('#fullname').length > 0) {
		var fullname = $('#fullname').val().split(' ');
		var first_name = '';
		$('#last_name').val(fullname.slice(-1));
		for (var i = 0; i < fullname.length - 1; i++) {
			if (i > 0) {
				first_name += ' ';
			}
			first_name += fullname[i];
		}
		$('#first_name').val(first_name);
	}

	if ($('#fullname_hidden').length > 0) {
		var fullname_hidden = $('#first_name').val() + ' ' + $('#last_name').val();
		$('#fullname_hidden').val(fullname_hidden)
	}

	var params = $('#findDeals').serialize();

	var lead_src = '',
		partner = $('#partner_api').val();
		lead_src = 'ENG-' + partner;

	var pathName = window.location.pathname;

	var thanks = $('#partner_ty').val();

	thanks = thanks.split(',').length > 1 ? ($('#property-business').is(':checked') || $('#connection_type').val() === 'Business' || $('input[name=connection_type]:checked').val() === 'Business' ? thanks.split(',')[1] : thanks.split(',')[0]) : thanks;

	if (partner === 'cc' || partner === 'thoughtworld') {
		apikey = 'kpl310gxrvo81yn2ewci';
	}

	// Add the utms to the params
	if (utms.length > 0) {

		params += '&' + $.param(JSON.parse(api_utms));

		thanks += utmParam + '&';
	}

	// thanks += 'email=' + $('input[name=email]').val() + '&phone=' + $('input[name=phone]').val();

	// Add the landing page to the params
	if (landingPage.length > 0)
		params += '&landing_page=' + encodeURIComponent(landingPage);


	if (
		// if page is resi or business option selected and partner is 'mic'
		// ((typeof(page_slug) != "undefined" && page_slug.indexOf('-resi') !== -1) || ($('#property-business').is(':checked') || $('#connection_type').val() === 'Business') && partner === 'mic') ||

		// if partner is mic
		partner === 'mic' ||

		// if business selected on these pages /form/step1-yc/ and /form/step1-tw/
		($('#property-business').is(':checked') || $('#connection_type').val() === 'Business' || $('input[name=connection_type]:checked').val() === 'Business'))
	{
		apikey = 'iPUTsJ68ytg4ARKNFM0L';
	} else if((partner === 'yc' || partner == 'youcompare') && $('input[name=state]').val().toLowerCase() == 'act') {
		apikey = 'kpl310gxrvo81yn2ewci';
		thanks = '/thank-you-tw/?';
	}



	if((!$('#property-business').is(':checked') || !$('#connection_type').val() === 'Business') && partner === 'mic') {
		apikey = 'iPUTsj68ytg4ARKNFM0L';

		if(pathName.indexOf('-resi') == -1) // if page is not resi
			apikey = 'kpl310gxrvo81yn2ewci';
	}

	// if partner is mic and the page is resi
	if(partner === 'mic' && (pathName.indexOf('-resi') !== -1 || pathName.indexOf('-wbill') !== -1)) {
		apikey = 'iPUTsJ68ytg4ARKNFM0L';
		thanks = $('#partner_ty').val();
	}


	// if partner is mic but not resi
	if(partner == 'mic' && (!$('#property-business').is(':checked') || !$('#connection_type').val() === 'Business')) {

	}


	params += '&apikey=' + apikey;

	// add sms_verified param to determine if sms verification was done before lead submission
	params += '&sms_verified=' + sms_verified;

	//  add the partner_slug set on the first page viewed
	// params += '&_partner=' + getCookie('partner_slug');

	params += '&ga_client_id=' + clientId;

	// this will be used for ty page
	setCookie('lead_src', lead_src, 2);
	localStorage.lead_src = lead_src;

	var params_arr = params.split('&');
	for (var x = 0; x < params_arr.length; x++) {}
	
	var paramsJson = jsonConvert(params);

	// add bill data
	// if(bill_data.length > 0 && partner === 'mic') {
	// 	paramsJson.files = bill_data;
	// }

	// if bill was upload submit to MIC
	if(typeof paramsJson.bill_uuid !== 'undefined') {
		paramsJson.apikey = 'iPUTsJ68ytg4ARKNFM0L';
		paramsJson.partner_api = 'mic';
		paramsJson.partner_ty = '/thank-you-mic/?';
		thanks = paramsJson.partner_ty;
	}


	// if partner is GoSwitch
	if(window.location.href.indexOf('stepn-gs') > 0) {
		paramsJson.apikey = 'GWddYmP8vqEPitQoDuBI';
	}

	// if partner is Zembl
	if(window.location.href.indexOf('stepn-zembl') > 0) {
		paramsJson.apikey = 'GWddYmP8vqEPitQoDuBI';
	}
	
	// if mic fail bill upload / great plan
	if(typeof paramsJson.current_plan !== 'undefined')
		thanks += 'current_plan=' + paramsJson.current_plan;

	for(var field in paramsJson) {
			if(typeof paramsJson[field] == 'string') {
				paramsJson[field] = paramsJson[field].replace(/\+/g,' ');
			}
	}

	var api_partner_name = $('#partner_api').val();

	if(api_partner_name.indexOf('mic') > -1) {
		paramsJson.apikey = 'iPUTsJ68ytg4ARKNFM0L';
	}
		

	if(checkCookie('lead_params')) {
		console.log('redirect if cookie exists');
		// window.location.href = thanks;
		// return false;
	} else {
		setCookie('lead_params', JSON.stringify(paramsJson), 0.0347222222);
	}
	
	paramsJson.apikey = "asdfhlj2h345adfjkhl45CAG";
	paramsJson.partner_rule = $('#partner_api').val();
	paramsJson.partner_api = $('#partner_api').val();
	paramsJson.account_type = $('input[name=connection_type]:checked').val();
	$.ajax({
		url: apiUrl + 'lead/create',
		dataType: 'json',
		type: 'POST',
		data: paramsJson,
		success: function(data) {
			console.log(data, 'success')
			if (data.error === null) {
				// set cookie for successful submission
				setCookie('current', 'yes', 0.0347222222);
				localStorage.current = 'yes';
				$('#loader').removeClass('show');
				window.location.href = thanks + '&uuid=' + data.data.lead_uuid;
			}
		},
		error: function(e) {
			console.log(e, 'fail')
			$('#loader').removeClass('show');
			$('.submit-step, .go-submit, .form-submit, .submit_verification, .submit-btn').removeClass('disable-submit').removeAttr('disabled');
		}
	});
}



// sms
// verify code
var checkSmsCode = function (userSmsCode) {
	return (userSmsCode === '9876' && (location.href.indexOf('dev.') !== -1 || location.href.indexOf('staging.') !== -1 || location.href.indexOf('.loc') !== -1)) || parseInt(userSmsCode) === smsVerificationCode;
}

// send verification
var submitSMScode = function (userMobile) {
	$.ajax({
		url: "/Controllers/sms-verification.php",
		dataType: "json",
		type: "POST",
		data: {
			from: 'ElectricGas',
			phone: userMobile
		},
		success: function(response) {
			smsVerificationCode = response.data.verification_code;
			console.log(smsVerificationCode);
		},
		error: function() {
			console.log("error sending sms");
		}
	});
}

// json Converter
function jsonConvert(string) {
	return JSON.parse('{"' + string.replace(/&/g, '","').replace(/=/g,'":"') + '"}', function(key, value) { return key===""?value:decodeURIComponent(value) })
}
